body {
  display: flex;
}

#root {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.ant-layout-header {
  z-index: 3 !important;
  background-color: white;
}

/* Page Scroll */
.wrap-page-scroll {
  height: calc(100% - 72px);
  width: 100%;
  position: absolute;
  .page-scrollable {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    > .ant-card {
      border-radius: 40px !important;
      box-shadow: none;
    }
  }
}

.wrap-page-footer {
  position: fixed;
  bottom: 10px;
  width: calc(100vw - 315px);
  height: 60px;
  margin-top: 10px;
  z-index: 10;
  .ant-card {
    border: 1px solid $border-primary-color;
    // backdrop-filter: blur(8px);
    border-radius: 1000px;
    .ant-card-body {
      padding: 12px 18px;
    }
  }
}

@mixin line-clamp($numLines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
}

.text-truncate-1 {
  word-break: break-word;
  @include line-clamp(1);
}

.text-truncate-2 {
  word-break: break-word;
  @include line-clamp(2);
}
.text-truncate-3 {
  word-break: break-word;
  @include line-clamp(3);
}

.text-wrap {
  text-wrap: wrap;
}

.center {
  @extend .flex;
  @extend .center-content;
}
.pointer {
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}

.gap-8px {
  gap: 8px;
}

.flex {
  display: flex;
  &.gap-4 {
    gap: 16px;
  }
  &.gap-2 {
    gap: 8px;
  }
  &.gap-1 {
    gap: 4px;
  }
  &.column {
    flex-direction: column;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.auto {
    flex: auto;
  }
  &.center-items {
    align-items: center;
  }
  &.center-content {
    justify-content: center;
  }
  &.right-content {
    justify-content: flex-end;
  }
  &.between-content {
    justify-content: space-between;
  }
  .flex-1 {
    flex: 1;
  }
}
.inline-flex {
  display: inline-flex;
}

// Color
.error {
  color: $color-error;
}
.text-muted {
  color: #ccc;
  font-size: 12px;
}
.text-disable {
  color: #ccc;
}
.table-sort {
  cursor: pointer;
  .active {
    color: #1d4449cc;
  }
}

// FONT
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
