$primary-color: #f5b700; // 5175b7
$primary-1: #f5b700;
$primary-6: #f5b700;
$text-color: #1d4449cc;
$text-dark-color: #1d4449;
$text-light-color: #1d444980;
$text-color-secondary: #1d444980;
$heading-color: #1d4449;
$color-error: #e7695f;
$color-success: #689f38;
$place-holder-text: #1d444952;

$border-color: #e4e6ef;
$border-primary-color: #f5b700;
$border-color-split: #e4e6ef;

$layout-bg-color: #f6faff;
$layout-bg-white: #fff;

// vertical paddings
$padding-md: 16px; // small containers and buttons

// vertical padding for all form controls
$control-padding-horizontal: 16px;

// height rules
$height-lg: 40px;
$line-height-base: 1.5;

$border-radius-base: 6px;
$font-size-base: 12px;
$popover-bg: #fff;
$shadow-1-down: 0 2px 8px rgba(0, 0, 0, 0.15);
