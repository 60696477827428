.error-avatar {
  width: 100% !important;
  height: auto !important;
}

.error-code {
  color: #434e59 !important;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.error-title {
  color: #434e59 !important;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.error-description {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
